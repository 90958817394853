(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('ExternalAuthKeyGenerationDialogController', ExternalAuthKeyGenerationDialogController);

  ExternalAuthKeyGenerationDialogController.$inject = ['Notification', '$translate', '$uibModalInstance', 'externalAuthKeyGenerationResponse'];

  function ExternalAuthKeyGenerationDialogController(Notification, $translate, $uibModalInstance, externalAuthKeyGenerationResponse) {
    var vm = this;
    vm.externalAuthKeyGenerationResponse = externalAuthKeyGenerationResponse;
    vm.clear = clear;
    vm.close = close;

    vm.copyExternalAuthKey = function () {
      var externalAuthKey = vm.externalAuthKeyGenerationResponse.apiKey; // Get the API key value
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(externalAuthKey)
          .then(function () {
            Notification.warning({
              message: $translate.instant('eDocumentsApp.externalAuthKey.generation.keyCopied'),
            });
          })
          .catch(function () {
            Notification.error({
              message: $translate.instant('eDocumentsApp.externalAuthKey.generation.keyCopyError'),
            });
          });
      } else {
        // Fallback for browsers that don't support Clipboard API
        var inputElement = document.getElementById('externalAuthKeyInput');
        inputElement.select();
        document.execCommand('copy');
        Notification.warning({
          message: $translate.instant('eDocumentsApp.externalAuthKey.generation.keyCopied'),
        });
      }
    };

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function close() {
      $uibModalInstance.close();
    }
  }
})();
