(function () {
  'use strict';

  angular.module('eDocumentsApp').config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('document', {
        parent: 'app',
        url: '/document?page&sort&search',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
          pageTitle: 'eDocumentsApp.document.home.title',
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/document/documents.html',
            controller: 'DocumentController',
            controllerAs: 'vm',
          },
        },
        params: {
          page: {
            value: '1',
            squash: true,
          },
          sid: null,
          eid: null,
          did: null,
          oid: null,
          sort: {
            value: 'createdDate,desc',
            squash: true,
          },
          search: null,
        },
        resolve: {
          pagingParams: [
            '$stateParams',
            'PaginationUtil',
            function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search,
              };
            },
          ],
          communicationDefinition: [
            '$stateParams',
            'CommunicationDefinition',
            function ($stateParams, CommunicationDefinition) {
              if ($stateParams.sid) {
                return CommunicationDefinition.get({ id: $stateParams.sid }).$promise;
              }
              return undefined;
            },
          ],
          loggeduser: [
            'Principal',
            function (Principal) {
              return Principal.identity().$promise;
            },
          ],
          origin: [
            '$stateParams',
            'CommunicationEntity',
            function ($stateParams, CommunicationEntity) {
              if ($stateParams.oid) {
                return CommunicationEntity.get({ id: $stateParams.oid }).$promise;
              }
              return undefined;
            },
          ],
          destination: [
            '$stateParams',
            'CommunicationEntity',
            function ($stateParams, CommunicationEntity) {
              if ($stateParams.did) {
                return CommunicationEntity.get({ id: $stateParams.did }).$promise;
              }
              return undefined;
            },
          ],
          entity: [
            '$stateParams',
            'CommunicationEntity',
            function ($stateParams, CommunicationEntity) {
              if ($stateParams.eid) {
                return CommunicationEntity.get({ id: $stateParams.eid }).$promise;
              }
              return undefined;
            },
          ],
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('document');
              $translatePartialLoader.addPart('documentType');
              $translatePartialLoader.addPart('documentStatus');
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            },
          ],
        },
      })
      .state('document-detail', {
        parent: 'document',
        url: '/document/{id}',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
          pageTitle: 'eDocumentsApp.document.detail.title',
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/document/document-detail.html',
            controller: 'DocumentDetailController',
            controllerAs: 'vm',
          },
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('document');
              $translatePartialLoader.addPart('documentType');
              $translatePartialLoader.addPart('documentStatus');
              return $translate.refresh();
            },
          ],
          loggeduser: [
            'Principal',
            function (Principal) {
              return Principal.identity().$promise;
            },
          ],
          entity: [
            '$stateParams',
            'Document',
            function ($stateParams, Document) {
              return Document.get({ id: $stateParams.id }).$promise.then(
                function (result) {
                  return result;
                },
                function () {
                  return null;
                }
              );
            },
          ],
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name || 'document',
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },
      })
      .state('document.delete', {
        parent: 'document',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/document/document-delete-dialog.html',
                controller: 'DocumentDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                  entity: [
                    'Document',
                    function (Document) {
                      return Document.get({ id: $stateParams.id }).$promise;
                    },
                  ],
                },
              })
              .result.then(
                function () {
                  $state.go('document', null, { reload: 'document' });
                },
                function () {
                  $state.go('^');
                }
              );
          },
        ],
      });
  }
})();
