(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('CommunicationDefinitionController', CommunicationDefinitionController);

    CommunicationDefinitionController.$inject = [
        '$state',
        'CommunicationDefinition',
        'ParseLinks',
        'AlertService',
        'paginationConstants',
        'pagingParams',
        'Notification',
        'PaginationUtil',
    ];

    function CommunicationDefinitionController(
    $state,
    CommunicationDefinition,
    ParseLinks,
    AlertService,
    paginationConstants,
    pagingParams,
    Notification,
    PaginationUtil
  ) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.copy = copy;
        vm.loadAll = loadAll;

        vm.totalItems = 1000;

        vm.reset = reset;
        vm.isSearchActive = isSearchActive;
        vm.changeActive = changeActive;
        vm.changeDefaultForOrigin = changeDefaultForOrigin;
        vm.changeDefaultForDestination = changeDefaultForDestination;

        function loadAll() {
            vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);

            CommunicationDefinition.query(
                {
                    page: vm.filters.currentPage,
                    size: vm.filters.itemsPerPage,
                    search: search(),
                    sort: sort(),
                },
        onSuccess,
        onError
      );

            function sort() {
                if (!vm.predicate) {
                    vm.predicate = 'id';
                }
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.communicationDefinitions = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function copy(definition) {
            CommunicationDefinition.copy({ id: definition.id }).$promise.then(
        function () {
            Notification.success({ message: 'Copiado com sucesso' });
            loadAll();
        },
        function () {
            Notification.error({ message: 'Ocorreu um erro ao copiar a definição' });
        }
      );
        }

        function search() {
            var result = '';
            for (var x in vm.filters.filterBy) {
                if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                    result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
                }
            }
            return result;
        }

        function isSearchActive() {
            return (
        vm.filters.filterBy.description.value ||
        angular.isDefined(vm.filters.filterBy.active.value) ||
        angular.isDefined(vm.filters.filterBy.defaultForOrigin.value) ||
        angular.isDefined(vm.filters.filterBy.defaultForDestination.value) ||
        vm.filters.filterBy.originName.value ||
        vm.filters.filterBy.originGln.value ||
        vm.filters.filterBy.destinationName.value ||
        vm.filters.filterBy.destinationGln.value
            );
        }

        function reset() {
            vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
            loadAll();
        }

        function changeActive(value) {
            vm.filters.filterBy.active.value = value;
            vm.page = 1;
            loadAll();
        }

        function changeDefaultForOrigin(value) {
            vm.filters.filterBy.defaultForOrigin.value = value;
            vm.page = 1;
            loadAll();
        }

        function changeDefaultForDestination(value) {
            vm.filters.filterBy.defaultForDestination.value = value;
            vm.page = 1;
            loadAll();
        }

        function initFilters() {
            vm.filters = PaginationUtil.getFilters($state.current.name, filterBy());

            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            loadAll();
        }

        initFilters();

        function filterBy() {
            return {
                description: {
                    name: 'description',
                    value: undefined,
                },
                active: {
                    name: 'active',
                    value: undefined,
                },
                defaultForOrigin: {
                    name: 'defaultForOrigin',
                    value: undefined,
                },
                defaultForDestination: {
                    name: 'defaultForDestination',
                    value: undefined,
                },
                originName: {
                    name: 'origin.name',
                    value: undefined,
                },
                originGln: {
                    name: 'origin.gln',
                    value: undefined,
                },
                destinationName: {
                    name: 'destination.name',
                    value: undefined,
                },
                destinationGln: {
                    name: 'destination.gln',
                    value: undefined,
                },
            };
        }
    }
})();
