(function () {
    'use strict';

    angular.module('eDocumentsApp').factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window'];

    function DataUtils($window) {
        var JSON_TYPE = 'JSON';
        var STRING_TYPE = 'STRING';
        var XML_TYPE = 'XML';

        return {
            abbreviate: abbreviate,
            viewServerFile: viewServerFile,
            byteSize: byteSize,
            formatFileSize: formatFileSize,
            openFile: openFile,
            toBase64: toBase64,
            parseHeaderFilename: parseHeaderFilename,
            getInputType: getInputType,
            getErrorMessageFromArrayBuffer: getErrorMessageFromArrayBuffer,
            getViewableFilename: getViewableFilename,
            getFileIconClass: getFileIconClass,
            JSON_TYPE: JSON_TYPE,
            STRING_TYPE: STRING_TYPE,
            XML_TYPE: XML_TYPE,
        };

        function viewServerFile(result) {
            var url = URL.createObjectURL(new Blob([result.data]));
            var link = document.createElement('a');
            link.href = url;
            link.style = 'visibility:hidden';
            if (result.filename.length > 6 && result.filename.substring(5, 6) === '_') {
                result.filename = result.filename.substring(6);
            }
            link.download = result.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? text.substring(0, 15) + '...' + text.slice(-10) : '';
        }

        function parseHeaderFilename(headers) {
            var header = headers('content-disposition');
            if (!header) {
                return null;
            }
            if (header === null) {
                return null;
            }
            var result = header.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return (base64String.length / 4) * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function formatFileSize(bytes) {
            if (bytes < 1024) {
                return bytes + ' B';
            } else if (bytes < Math.pow(1024, 2)) {
                return (bytes / 1024).toFixed(2) + ' KB';
            } else if (bytes < Math.pow(1024, 3)) {
                return (bytes / Math.pow(1024, 2)).toFixed(2) + ' MB';
            } else if (bytes < Math.pow(1024, 4)) {
                return (bytes / Math.pow(1024, 3)).toFixed(2) + ' GB';
            } else if (bytes < Math.pow(1024, 5)) {
                return (bytes / Math.pow(1024, 4)).toFixed(2) + ' TB';
            } else {
                return (bytes / Math.pow(1024, 5)).toFixed(2) + ' PB';
            }
        }

        function openFile(type, data) {
            var fileURL = 'data:' + type + ';base64,' + data;
            var win = $window.open('', '_blank');
            win.document.write(
        '<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>'
      );
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substring(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function getInputType(input) {
            try {
        //try to parse via json
                angular.fromJson(input);
                return JSON_TYPE;
            } catch (e) {
                try {
                    $.parseXML(input); //is valid XML
                    return XML_TYPE;
                } catch (err) {
          // was not XML
                    return STRING_TYPE;
                }
            }
        }

        function getErrorMessageFromArrayBuffer(data) {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
            var obj = JSON.parse(decodedString);
            return obj['message'];
        }

        function getViewableFilename(filename) {
      // Define the regex pattern: 2 alphanumeric chars, "_", 2 alphanumeric chars, "_"
            var pattern = /^[a-zA-Z0-9]{2}_[a-zA-Z0-9]{2}_/;

      // Check if the filename starts with the pattern and remove the first 6 characters if true
            if (pattern.test(filename)) {
                return filename.substring(6);
            }

      // Return the original filename if the pattern doesn't match
            return filename;
        }

        function getFileIconClass(fileType) {
            var fileTypeIconMap = {
        // Text Files
                PLAIN_TEXT: 'fa-file-alt', // Plain Text
                HTML: 'fa-file-code', // HTML
                CSS: 'fa-file-code', // CSS
                JAVASCRIPT: 'fa-file-code', // JavaScript
                JSON: 'fa-file-code', // JSON
                XML: 'fa-file-code', // XML
                MARKDOWN: 'fa-file-alt', // Markdown

        // Document Files
                PDF: 'fa-file-pdf', // PDF
                DOC: 'fa-file-word', // DOC
                DOCX: 'fa-file-word', // DOCX
                ODT: 'fa-file-word', // ODT
                RTF: 'fa-file-alt', // RTF

        // Spreadsheet Files
                XLS: 'fa-file-excel', // XLS
                XLSX: 'fa-file-excel', // XLSX
                ODS: 'fa-file-excel', // ODS

        // Presentation Files
                PPT: 'fa-file-powerpoint', // PPT
                PPTX: 'fa-file-powerpoint', // PPTX
                ODP: 'fa-file-powerpoint', // ODP

        // Image Files
                JPEG: 'fa-file-image', // JPEG
                PNG: 'fa-file-image', // PNG
                GIF: 'fa-file-image', // GIF
                BMP: 'fa-file-image', // BMP
                TIFF: 'fa-file-image', // TIFF
                SVG: 'fa-file-image', // SVG

        // Audio Files
                MP3: 'fa-file-audio', // MP3
                WAV: 'fa-file-audio', // WAV
                AAC: 'fa-file-audio', // AAC
                OGG: 'fa-file-audio', // OGG
                FLAC: 'fa-file-audio', // FLAC

        // Video Files
                MP4: 'fa-file-video', // MP4
                AVI: 'fa-file-video', // AVI
                MKV: 'fa-file-video', // MKV
                MOV: 'fa-file-video', // MOV
                WEBM: 'fa-file-video', // WEBM

        // Compressed Files
                ZIP: 'fa-file-archive', // ZIP
                RAR: 'fa-file-archive', // RAR
                GZIP: 'fa-file-archive', // GZIP
                TAR: 'fa-file-archive', // TAR

        // Binary and Executable Files
                EXE: 'fa-file', // EXE
                DLL: 'fa-file', // DLL
                BIN: 'fa-file', // BIN (default for unknown binary files)

        // Custom Application Files
                UNEDIFACT: 'fa-file-invoice', // UNEDIFACT

        // Default Type for Unknown MIME Types
                UNKNOWN: 'fa-file',
            };

      // Return the icon class based on the file type, or default if not found
            return fileTypeIconMap[fileType.toUpperCase()] || fileTypeIconMap['UNKNOWN'];
        }
    }
})();
