(function () {
    'use strict';

    angular.module('eDocumentsApp').factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal($q, Account) {
        var _identity,
            _authenticated = false;

        return {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isUserEntityManager: isUserEntityManager,
            isIdentityResolved: isIdentityResolved,
        };

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function isUserEntityManager(communicationEntityId) {
            if (!_authenticated || !_identity || !_identity.userEntities) {
                return false;
            }

            if (_identity.userEntities && _identity.userEntities.length > 0) {
                for (var i = 0; i < _identity.userEntities.length; i++) {
                    if (_identity.userEntities[i].entityId === communicationEntityId) {
                        return _identity.userEntities[i].manager;
                    }
                }
            }

            return false;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(
        function (_id) {
            return _id.authorities && _id.authorities.indexOf(authority) !== -1;
        },
        function () {
            return false;
        }
      );
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

      // check and see if we have retrieved the identity data from the server.
      // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

      // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise.then(getAccountThen).catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account.data;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }
    }
})();
