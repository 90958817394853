(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('DocumentExternalController', DocumentExternalController);

    DocumentExternalController.$inject = [
        '$stateParams',
        '$uibModal',
        '$translate',
        '$sce',
        'Document',
        'DocumentFile',
        'DocumentDownloadPdf',
        'Notification',
        'DataUtils',
        'CurrencyUtils',
    ];

    function DocumentExternalController(
    $stateParams,
    $uibModal,
    $translate,
    $sce,
    Document,
    DocumentFile,
    DocumentDownloadPdf,
    Notification,
    DataUtils,
    CurrencyUtils
  ) {
        var vm = this;

        vm.hashMissing = angular.isUndefined($stateParams.hash);
        vm.error = null;
        vm.download = download;
        vm.downloadAllFiles = downloadAllFiles;
        vm.getCurrency = CurrencyUtils.getCurrency;
        vm.getFileIconClass = DataUtils.getFileIconClass;
        vm.success = null;
        vm.viewableFile = null;

        if (!vm.hashMissing) {
            Document.getByExternalHash(
                {
                    hash: $stateParams.hash,
                },
        function (result) {
            vm.externalDocument = result;
            vm.approvalInfo = {
                approvedBy: vm.externalDocument.approvedBy,
                approvedDate: vm.externalDocument.approvedDate,
            };
            Document.addExternalView(vm.externalDocument.hashedId);
            if (angular.isDefined(vm.externalDocument.files) && vm.externalDocument.files.length > 0) {
                var pdfDocumentFile = vm.externalDocument.files.find(isPDF);
                if (angular.isDefined(pdfDocumentFile)) {
                    Document.downloadNeedApprovalExternal({ hash: vm.externalDocument.hashedId }).$promise.then(
                function (result) {
                    var downloadNeedApproval = result.body;

                  // SELFBILLED documents will never be sent with an external access hash
                    if (
                    (vm.externalDocument.documentType === 'SELFBILLED' || vm.externalDocument.documentType === 'CREDITNOTE') &&
                    !vm.externalDocument.approved &&
                    downloadNeedApproval === 'true'
                  ) {
                        $uibModal
                      .open({
                          templateUrl: 'app/entities/document/document-approval-by-hashid-dialog.html',
                          controller: 'DocumentApprovalByHashIdController',
                          controllerAs: 'vm',
                          size: 'md',
                          resolve: {
                              entity: [
                                  'Document',
                                  function (Document) {
                                      return Document.getByExternalHash({ hash: vm.externalDocument.hashedId }).$promise;
                                  },
                              ],
                          },
                      })
                      .result.then(function () {
                          Document.getByExternalHash(
                              {
                                  hash: $stateParams.hash,
                              },
                          function (result) {
                              vm.externalDocument = result;
                              vm.approvalInfo = {
                                  approvedBy: vm.externalDocument.approvedBy,
                                  approvedDate: vm.externalDocument.approvedDate,
                              };
                          },
                          function (err) {
                              if (err.status === 404) {
                                  Notification.error({ message: $translate.instant('eDocumentsApp.document.message.documentNotFound') });
                              } else {
                                  Notification.error({
                                      message:
                                  $translate.instant('eDocumentsApp.document.message.errorDownloading') +
                                  ': ' +
                                  $translate.instant('error.' + DataUtils.getErrorMessageFromArrayBuffer(err.data.data)),
                                  });
                              }
                          }
                        );
                          getViewableFile(pdfDocumentFile.hashedId);
                      });
                    } else {
                        getViewableFile(pdfDocumentFile.hashedId);
                    }
                },
                function (e) {
                    Notification.error({ message: e.statusText });
                }
              );
                }
            }
        },
        function (error) {
            vm.externalDocument = null;
            vm.error = error;
        }
      );
        }

        function getViewableFile(fileHashedId) {
            DocumentFile.externalDownload({ hash: fileHashedId }).$promise.then(
        function (result) {
            var file = new Blob([result.data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            vm.viewableFile = $sce.trustAsResourceUrl(fileURL);
            DocumentFile.addExternalDownload(fileHashedId);
        },
        function (err) {
            processDownloadError(err);
        }
      );
        }

        function processDownloadError(error) {
            if (error.status === 404) {
                Notification.error({ message: $translate.instant('eDocumentsApp.document.message.documentNotFound') });
            } else {
                Notification.error({
                    message:
            $translate.instant('eDocumentsApp.document.message.errorDownloading') +
            ': ' +
            $translate.instant('error.' + DataUtils.getErrorMessageFromArrayBuffer(error.data.data)),
                });
            }
        }

        function isPDF(documentFile) {
            return documentFile.fileType === 'PDF';
        }

        function download($event, fileHashedId) {
            DocumentDownloadPdf.downloadExternal($event, vm.externalDocument, fileHashedId);
        }

        function downloadAllFiles($event) {
            DocumentDownloadPdf.downloadAllFilesExternal($event, $stateParams.hash, vm.externalDocument);
        }
    }
})();
