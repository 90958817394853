(function () {
  'use strict';

  angular.module('eDocumentsApp').factory('PaginationUtil', PaginationUtil);

  PaginationUtil.$inject = ['$localStorage', 'MyUtil'];

  function PaginationUtil($localStorage, MyUtil) {
    return {
      parseAscending: parseAscending,
      parsePage: parsePage,
      parsePredicate: parsePredicate,
      clearFilters: clearFilters,
      getFilters: getFilters,
      resetFilters: resetFilters,
      updateFilters: updateFilters,
    };

    function parseAscending(sort) {
      var sortArray = sort.split(',');
      if (sortArray.length > 1) {
        return sort.split(',').slice(-1)[0] === 'asc';
      } else {
        // default to true if no sort defined
        return true;
      }
    }

    // query params are strings, and need to be parsed
    function parsePage(page) {
      return parseInt(page);
    }

    // sort can be in the format `id,asc` or `id`
    function parsePredicate(sort) {
      var sortArray = sort.split(',');
      if (sortArray.length > 1) {
        sortArray.pop();
      }
      return sortArray.join(',');
    }

    function resetFilters() {
      delete $localStorage.listState202502a;
    }

    function clearFilters(state, emptySearch) {
      var filters = {
        currentPage: 0,
        itemsPerPage: 20,
        filterBy: angular.copy(emptySearch),
        orderBy: undefined,
        orderReverse: undefined,
      };
      $localStorage.listState202502a[state] = filters;
      return angular.copy(filters);
    }

    function getFilters(state, emptySearch) {
      if (!$localStorage.listState202502a) {
        $localStorage.listState202502a = {};
      }
      var query = $localStorage.listState202502a[state];
      if (!MyUtil.isEmpty(query)) {
        return query;
      }
      return clearFilters(state, emptySearch);
    }

    function updateFilters(state, currentPage, filter, orderBy, orderReverse, itemsPerPage) {
      var filters = {
        currentPage: currentPage,
        filterBy: angular.copy(filter),
        itemsPerPage: itemsPerPage,
        orderBy: angular.copy(orderBy),
        orderReverse: angular.copy(orderReverse),
      };
      $localStorage.listState202502a[state] = filters;
      return angular.copy(filters);
    }
  }
})();
