(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('ExternalAuthKeyDialogController', ExternalAuthKeyDialogController);

  ExternalAuthKeyDialogController.$inject = ['$state', '$timeout', '$translate', '$uibModal', 'entity', 'ExternalAuthKey', 'Notification'];

  function ExternalAuthKeyDialogController($state, $timeout, $translate, $uibModal, entity, ExternalAuthKey, Notification) {
    var vm = this;
    vm.communicationEntity = entity;
    vm.clear = clear;
    vm.save = save;

    activate();

    function activate() {
      if (!entity || !entity.id) {
        $state.go('communication-entity');
        Notification.error({
          message: $translate.instant('eDocumentsApp.externalAuthKey.message.error.communicationEntityUndefined'),
        });
      } else {
        vm.amaSafeCredential = {};
        vm.amaSafeCredential.communicationEntityId = entity.id;
        $uibModal
          .open({
            templateUrl: 'app/entities/external-auth-key/external-auth-key-disclaimer-dialog.html',
            controller: 'ExternalAuthKeyDisclaimerDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
          })
          .result.then(
            function () {
              // do nothing
            },
            function () {
              $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
            }
          );
      }
    }

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
    }

    function save() {
      vm.isSaving = true;
      var postObject = {
        communicationEntityId: vm.communicationEntity.id,
        processId: vm.externalAuthKey.processId,
      };
      ExternalAuthKey.generate(postObject, onSaveSuccess, onSaveError);
    }

    function onSaveSuccess(result) {
      vm.isSaving = false;
      $uibModal
        .open({
          templateUrl: 'app/entities/external-auth-key/external-auth-key-generation-dialog.html',
          controller: 'ExternalAuthKeyGenerationDialogController',
          controllerAs: 'vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            externalAuthKeyGenerationResponse: result,
          },
        })
        .result.then(
          function () {
            $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
          },
          function () {
            $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
          }
        );
    }

    function onSaveError(error) {
      vm.isSaving = false;
      if (error.data.detail) {
        Notification.error({ message: $translate.instant(error.data.detail) });
      }
    }
  }
})();
