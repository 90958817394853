/* global saveAs:true */
(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeUtilController', AmaSafeUtilController);

    AmaSafeUtilController.$inject = ['$scope', '$uibModal', '$timeout', 'DataUtils', 'AmaSafeUtilService'];

    function AmaSafeUtilController($scope, $uibModal, $timeout, DataUtils, AmaSafeUtilService) {
        var vm = this;
        vm.isSaving = false;
        vm.responseStatus = false;

        vm.authFormBody = {};
        vm.callAuthTest = callAuthTest;
        vm.clearAuthForm = clearAuthForm;

        vm.credentialFormBody = {};
        vm.callCredentialForm = callCredentialForm;
        vm.clearCredentialForm = clearCredentialForm;

        vm.signatureFormBody = { color: '#00FF00' };
        vm.setFile = setFile;
        vm.resetFile = resetFile;
        vm.callSignatureForm = callSignatureForm;
        vm.clearSignatureForm = clearSignatureForm;

        vm.goBack = goBack;

        function goBack() {
            window.history.back();
        }

        function openDialog(message) {
            $uibModal.open({
                templateUrl: 'app/entities/ama-safe-util/ama-safe-util-dialog.html',
                controller: 'AmaSafeUtilDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    responseStatus: vm.responseStatus,
                    message: message,
                },
            });
        }

        function callAuthTest() {
            vm.isSaving = true;
            AmaSafeUtilService.callAuthTest(vm.authFormBody, onSuccess, onError);
        }

        function clearAuthForm(form) {
            vm.authFormBody = {};
            form.$setPristine();
            $timeout(function () {
                angular.element('#authForm .form-group:eq(0)>input').focus();
            });
        }

        function callCredentialForm() {
            vm.isSaving = true;
            AmaSafeUtilService.getCredentialID(vm.credentialFormBody, onSuccess, onError);
        }

        function clearCredentialForm(form) {
            vm.credentialFormBody = {};
            form.$setPristine();
            $timeout(function () {
                angular.element('#credentialForm .form-group:eq(0)>input').focus();
            });
        }

        function onSuccess(data) {
            vm.isSaving = false;
            vm.responseStatus = true;
            openDialog(data);
        }

        function onError(err) {
            vm.isSaving = false;
            vm.responseStatus = false;
            openDialog(err.data);
        }

        function clearSignatureForm(form) {
            vm.signatureFormBody = { color: '#00FF00' };
            form.$setPristine();
            $timeout(function () {
                angular.element('#signatureForm .form-group:eq(0)>input').focus();
            });
        }

        function setFile($file) {
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    vm.signatureFormBody.file = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    vm.signatureFormBody.filename = $file.name;
                    $scope.$apply();
                };
            }
        }

        function resetFile() {
            vm.signatureFormBody.file = null;
            vm.signatureFormBody.filename = null;
        }

        function callSignatureForm() {
            var postObject = {
                file: vm.signatureFormBody.file,
                filename: vm.signatureFormBody.filename,
                color: vm.signatureFormBody.color,
                signXCoord: vm.signatureFormBody.signXCoord,
                signYCoord: vm.signatureFormBody.signYCoord,
                signWidth: vm.signatureFormBody.signWidth,
                signHeight: vm.signatureFormBody.signHeight,
            };

            AmaSafeUtilService.generateExample(postObject).$promise.then(function (result) {
                DataUtils.viewServerFile(result);
            });
        }

        $timeout(function () {
            angular.element('.color-picker-wrapper').css('padding', '0');
            angular.element('.color-picker-input').css('width', '300px');
            angular.element('.color-picker-input-wrapper').css('height', 'calc(1.5em + 0.45rem)');
            angular.element('.color-picker-input-wrapper > span').css('border-right-width', '1px');
            angular.element('.color-picker-input-wrapper > span').css('border-radius', '0.2rem');
            angular.element('.color-picker-grid-inner').css('background-color', 'transparent');
        });

        vm.colorPickerOptions = {
            required: true,
            disabled: false,
            inputClass: 'ng-hide',
            id: 'field_color',
            name: 'color',
            format: 'hexString',
            hue: false,
            alpha: false,
            round: true,
        };
    }
})();
