(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('ExternalAuthKeyRevokeController', ExternalAuthKeyRevokeController);

  ExternalAuthKeyRevokeController.$inject = ['$uibModalInstance', 'externalAuthKey'];

  function ExternalAuthKeyRevokeController($uibModalInstance, externalAuthKey) {
    var vm = this;

    vm.externalAuthKey = externalAuthKey;
    vm.clear = clear;
    vm.confirmRevoke = confirmRevoke;

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function confirmRevoke() {
      $uibModalInstance.close(true);
    }
  }
})();
