(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('ExternalEntityController', ExternalEntityController);

    ExternalEntityController.$inject = [
        '$state',
        '$translate',
        'communicationEntity',
        'ExternalEntity',
        'ParseLinks',
        'AlertService',
        'paginationConstants',
        'pagingParams',
        'PaginationUtil',
        'Notification',
        '$uibModal',
        '$interval',
    ];

    function ExternalEntityController(
    $state,
    $translate,
    communicationEntity,
    ExternalEntity,
    ParseLinks,
    AlertService,
    paginationConstants,
    pagingParams,
    PaginationUtil,
    Notification,
    $uibModal,
    $interval
  ) {
        var vm = this;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.communicationEntity = communicationEntity;
        vm.clear = clear;
        vm.search = search;
        vm.reset = reset;
        vm.loadAll = loadAll;
        vm.deselectOne = deselectOne;
        vm.selectAll = selectAll;
        vm.selectChange = selectChange;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.activateEntities = activateEntities;
        vm.getTotalSelected = getTotalSelected;
        vm.selectedEntities = {};
        vm.entitiesToSelection = {};
        vm.synchronize = synchronize;
        vm.activateBtnEnable = activateBtnEnable;
        vm.showTooltipOnActivateButton = showTooltipOnActivateButton;
        vm.activateEntity = activateEntity;
        vm.inactivateEntity = inactivateEntity;

        vm.synchronizeButtonProtected = true;
        vm.isSynchronizing = false;
        vm.lastSynchronizationData = {};

        vm.totalItems = 1000;

        vm.changeStatus = changeStatus;
        vm.isSearchActive = isSearchActive;

        vm.statuses = ['CREATED', 'PENDING', 'ACTIVE'];

        initFilters();

        function loadAll() {
            if (vm.stateCurrentName === $state.current.name) {
        // avoiding synchronization thread issues
                vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);
            }
            if (vm.communicationEntity) {
                ExternalEntity.query(
                    {
                        originEntityID: vm.communicationEntity.id,
                        query: search(),
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        sort: sort(),
                    },
          onSuccess,
          onError
        );
            }

            function sort() {
                if (!vm.predicate) {
                    vm.predicate = 'id';
                }
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.externalEntities = data;
                vm.entitiesToSelection = vm.externalEntities.filter(function (ent) {
                    return ent.status === 'CREATED';
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function initFilters() {
            vm.stateCurrentName = $state.current.name;
            vm.filters = PaginationUtil.getFilters($state.current.name, filterBy());
            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            validateSynchronization();
            loadAll();
        }

        $interval(function () {
            if (vm.isSynchronizing) {
                validateSynchronization();
            }
        }, 10000);

        function validateSynchronization() {
            ExternalEntity.getLastSynchronization(
        { communicationEntityId: vm.communicationEntity.id },
        function (result) {
            vm.lastSynchronizationData = result;
          // enable or disable the sync button if the process is not finished or if there is no data or if 1 hour has passed for the start time
            var syncStartDatePlusOneHour = moment(vm.lastSynchronizationData.startDate).add(60, 'm').toDate();
            var now = new Date();
            if (syncStartDatePlusOneHour < now || moment(vm.lastSynchronizationData.endDate).toDate() < now) {
                if (vm.isSynchronizing) {
                    if (moment(vm.lastSynchronizationData.endDate).toDate() < now && vm.lastSynchronizationData.numberEntitiesSynchronized >= 0) {
                // the reason why the previous validation passed was because it ended the synchronization
                        Notification.success({ message: $translate.instant('eDocumentsApp.externalEntity.message.synchronize.finishedSuccessfully') });
                    } else {
                        Notification.error({ message: $translate.instant('eDocumentsApp.externalEntity.message.synchronize.error') });
                    }
                }
                vm.synchronizeButtonProtected = false;
                vm.isSynchronizing = false;
                loadAll();
            } else {
                vm.synchronizeButtonProtected = true;
                vm.isSynchronizing = true;
            }
            vm.lastSynchronizationData.startDate = moment(vm.lastSynchronizationData.startDate).format('DD-MMM-YYYY HH:mm:ss');
            if (vm.lastSynchronizationData.endDate != null) {
                vm.lastSynchronizationData.endDate = moment(vm.lastSynchronizationData.endDate).format('DD-MMM-YYYY HH:mm:ss');
            }
        },
        function () {
            vm.lastSynchronizationData = {};
            vm.synchronizeButtonProtected = false;
            vm.isSynchronizing = false;
        }
      );
        }

        function filterBy() {
            return {
                name: {
                    name: 'name',
                    value: undefined,
                },
                status: {
                    name: 'status',
                    value: undefined,
                },
                country: {
                    name: 'country',
                    value: undefined,
                },
                vatRegistrationNumber: {
                    name: 'vatRegistrationNumber',
                    value: undefined,
                },
                postalCode: {
                    name: 'postalCode',
                    value: undefined,
                },
            };
        }

        function transition() {
            $state.transitionTo($state.$current, {
                origin: communicationEntity,
                page: vm.filters.currentPage + 1,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
            });
        }

        function changeStatus(value) {
            vm.filters.filterBy.status.value = value;
            vm.page = 1;
            loadAll();
        }

        function search() {
            var result = '';
            for (var x in vm.filters.filterBy) {
                if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                    result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
                }
            }
            return result;
        }

        function isSearchActive() {
            return (
        vm.filters.filterBy.name.value ||
        vm.filters.filterBy.country.value ||
        vm.filters.filterBy.vatRegistrationNumber.value ||
        vm.filters.filterBy.postalCode.value
            );
        }

        function reset() {
            vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function synchronize() {
            ExternalEntity.synchronize(
        vm.communicationEntity,
        function () {
            validateSynchronization();
            Notification.success({ message: $translate.instant('eDocumentsApp.externalEntity.message.synchronize.success') });
        },
        function () {
            Notification.error({ message: $translate.instant('eDocumentsApp.externalEntity.message.synchronize.error') });
        }
      );
        }

        function activateEntity(entity) {
            vm.selectedEntities = [];
            vm.selectedEntities[entity.id] = entity;
            vm.activateEntities();
            vm.selectedEntities = [];
        }

        function activateEntities() {
            var errorExists = false;
            var entitiesToActivate = [];
            var entitiesWithError = {};
            angular.forEach(vm.selectedEntities, function (ent) {
                if (ent.gln !== '' && ent.emailTo !== '') {
                    entitiesToActivate.push({ id: ent.id });
                } else {
                    errorExists = true;
                    entitiesWithError[ent.id] = ent;
                }
            });
            if (!errorExists) {
                $uibModal
          .open({
              templateUrl: 'app/entities/external-entity/external-entities-activate-confirmation-dialog.html',
              controller: 'ExternalEntitiesActivateConfirmationController',
              controllerAs: 'vm',
              size: 'md',
          })
          .result.then(
            function () {
                ExternalEntity.activate(
                JSON.stringify({ entities: entitiesToActivate }),
                function onSuccess(result) {
                    for (var i in result.success) {
                        Notification.success({ message: result.success[i].message });
                    }
                    for (var j in result.errors) {
                        Notification.error({ message: result.errors[j].message });
                    }
                    for (var k in result.warnings) {
                        Notification.warning({ message: result.warnings[k].message });
                    }

                    vm.selectedEntities = [];
                    checkSelectAll();
                    loadAll();
                },
                function onError() {
                    Notification.error({ message: $translate.instant('eDocumentsApp.externalEntity.activate.error') });
                }
              );
            },
            function () {
              // do nothing
            }
          );
            } else {
                $uibModal
          .open({
              templateUrl: 'app/entities/external-entity/external-entities-error-dialog.html',
              controller: 'ExternalEntitiesErrorController',
              controllerAs: 'vm',
              size: 'xl',
              resolve: {
                  list: entitiesWithError,
              },
          })
          .result.then(
            function () {
              // do nothing
            },
            function () {
              // do nothing
            }
          );
            }
        }

        function inactivateEntity(entity) {
            $uibModal
        .open({
            templateUrl: 'app/entities/external-entity/external-entities-inactivate-confirmation-dialog.html',
            controller: 'ExternalEntitiesInactivateConfirmationController',
            controllerAs: 'vm',
            size: 'md',
        })
        .result.then(
          function () {
              ExternalEntity.inactivate(
              entity,
              function onSuccess() {
                  Notification.success($translate.instant('eDocumentsApp.externalEntity.inactivate.message'));
                  loadAll();
              },
              function onError() {
                  Notification.error({ message: $translate.instant('eDocumentsApp.externalEntity.inactivate.error') });
              }
            );
          },
          function () {
            // do nothing
          }
        );
        }

        function checkSelectAll() {
            var entSelected = vm.externalEntities.filter(function (ent) {
                if (ent.status === 'CREATED') {
                    return vm.selectedEntities[ent.id] != null;
                }
            });
            vm.selectedAll = vm.entitiesToSelection.length === entSelected.length;
        }

        function getTotalSelected() {
            var keys = Object.keys(vm.selectedEntities);
            return keys.length;
        }

        function selectChange(entity) {
            if (entity.selected) {
                vm.selectedEntities[entity.id] = entity;
                checkSelectAll();
            } else {
                delete vm.selectedEntities[entity.id];
            }
        }

        function selectAll() {
            vm.entitiesToSelection.map(function (x) {
                x.selected = !vm.selectedAll;
                return x;
            });
            if (!vm.selectedAll) {
                for (var entityToSelection in vm.entitiesToSelection) {
                    vm.selectedEntities[entityToSelection.id] = entityToSelection;
                }
            } else {
                for (var entityToSelection2 in vm.entitiesToSelection) {
                    delete vm.selectedEntities[entityToSelection2.id];
                }
            }
        }

        function deselectOne() {
            vm.selectedAll = false;
        }

        function activateBtnEnable(entity) {
            return entity.status === 'CREATED' && entity.gln !== '' && entity.emailTo !== '';
        }

        function showTooltipOnActivateButton(entity) {
            return entity.status === 'CREATED' && (entity.gln === '' || entity.emailTo === '');
        }
    }
})();
