(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('ExternalAuthKeyDisclaimerDialogController', ExternalAuthKeyDisclaimerDialogController);

  ExternalAuthKeyDisclaimerDialogController.$inject = ['$uibModalInstance'];

  function ExternalAuthKeyDisclaimerDialogController($uibModalInstance) {
    var vm = this;
    vm.clear = clear;
    vm.accept = accept;

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function accept() {
      $uibModalInstance.close();
    }
  }
})();
