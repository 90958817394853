(function () {
  'use strict';
  angular.module('eDocumentsApp').factory('DocumentFile', DocumentFile);

  DocumentFile.$inject = ['$resource', 'DataUtils'];

  function DocumentFile($resource, DataUtils) {
    var resourceUrl = 'api/document-files/:id';

    return $resource(
      resourceUrl,
      {},
      {
        query: {
          method: 'GET',
          isArray: true,
        },
        queryByDocumentId: {
          method: 'GET',
          url: 'api/document-files-by-document/:documentId',
          isArray: true,
        },
        get: {
          method: 'GET',
          transformResponse: function (data) {
            if (data) {
              data = angular.fromJson(data);
            }
            return data;
          },
        },
        update: { method: 'PUT' },
        download: {
          url: 'api/document-filesDownload/:id',
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse: function (data, headers) {
            return {
              data: data,
              filename: DataUtils.parseHeaderFilename(headers),
            };
          },
        },
        externalDownload: {
          url: 'api/external/document-filesDownload/:hash',
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse: function (data, headers) {
            return {
              data: data,
              filename: DataUtils.parseHeaderFilename(headers),
            };
          },
        },
        addExternalDownload: {
          url: 'api/external/document-file-add-external-download',
          method: 'POST',
        },
      }
    );
  }
})();
