(function () {
  'use strict';

  angular.module('eDocumentsApp').config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('external-auth-key-new', {
      parent: 'entity',
      url: '/external-auth-key/new',
      data: {
        authorities: ['ROLE_SUPERADMIN', 'ROLE_USER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/entities/external-auth-key/external-auth-key-dialog.html',
          controller: 'ExternalAuthKeyDialogController',
          controllerAs: 'vm',
        },
      },
      params: {
        communicationEntityId: null,
      },
      resolve: {
        entity: [
          '$stateParams',
          'CommunicationEntity',
          function ($stateParams, CommunicationEntity) {
            if ($stateParams.communicationEntityId !== null) {
              return CommunicationEntity.get({ id: $stateParams.communicationEntityId }).$promise;
            }
            return null;
          },
        ],
        previousState: [
          '$state',
          function ($state) {
            return {
              name: $state.current.name || 'external-auth-key',
              params: $state.params,
              url: $state.href($state.current.name, $state.params),
            };
          },
        ],
        translatePartialLoader: [
          '$translate',
          '$translatePartialLoader',
          function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('externalAuthKey');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          },
        ],
      },
    });
  }
})();
