(function () {
  'use strict';
  angular.module('eDocumentsApp').factory('ExternalAuthKey', ExternalAuthKey);

  ExternalAuthKey.$inject = ['$resource'];

  function ExternalAuthKey($resource) {
    var resourceUrl = 'api/external-auth-keys/:id';

    return $resource(
      resourceUrl,
      { id: '@id' }, // Default parameter mapping
      {
        query: { method: 'GET', isArray: true },
        generate: {
          method: 'POST',
        },
        findByEntity: {
          method: 'GET',
          url: 'api/external-auth-keys-for-communication-entity/:communicationEntityId',
          isArray: true,
        },
        revoke: {
          method: 'PUT',
          url: 'api/external-auth-keys/revoke/:id',
        },
      }
    );
  }
})();
