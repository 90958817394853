/* global moment:true, _:true*/
(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('DigitalArchiveDocumentController', DigitalArchiveDocumentController);

  DigitalArchiveDocumentController.$inject = [
    '$state',
    '$scope',
    '$uibModal',
    '$translate',
    'DataUtils',
    'Document',
    'DocumentFile',
    'ParseLinks',
    'AlertService',
    'paginationConstants',
    'Notification',
    'PaginationUtil',
    'DocumentDownloadPdf',
    'CurrencyUtils',
    '$window',
  ];

  function DigitalArchiveDocumentController(
    $state,
    $scope,
    $uibModal,
    $translate,
    DataUtils,
    Document,
    DocumentFile,
    ParseLinks,
    AlertService,
    paginationConstants,
    Notification,
    PaginationUtil,
    DocumentDownloadPdf,
    CurrencyUtils,
    $window
  ) {
    var vm = this;
    vm.itemsPerPage = paginationConstants.itemsPerPage;
    vm.exportDocumentsFiles = exportDocumentsFiles;
    vm.search = search;
    vm.reset = reset;
    vm.loadAll = loadAll;
    vm.initFilters = initFilters;
    vm.totalItems = 1000;
    vm.isTableLoading = true;
    vm.deselectOne = deselectOne;
    vm.selectAll = selectAll;
    vm.selectChange = selectChange;
    vm.getTotalSelected = getTotalSelected;
    vm.viewSelectedDocuments = viewSelectedDocuments;
    vm.isSearchActive = isSearchActive;
    vm.getCurrency = CurrencyUtils.getCurrency;
    vm.download = download;
    vm.changeFiles = changeFiles;
    vm.changeVisualization = changeVisualization;
    vm.changeDocumentType = changeDocumentType;
    vm.getViewableFilename = DataUtils.getViewableFilename;
    vm.formatFileSize = DataUtils.formatFileSize;
    vm.getFileIconClass = DataUtils.getFileIconClass;
    vm.types = ['INVOICE', 'CREDITNOTE', 'DEBITNOTE', 'SELFBILLED', 'PROFORMAINVOICE', 'CONSIGNMENTNOTE', 'ORDER', 'OTHER'];

    vm.documentsToExport = {};

    vm.clearInputDate = clearInputDate;
    vm.startDateForCreatedDate = null;
    vm.endDateForCreatedDate = null;
    vm.startDateForDocumentDate = null;
    vm.endDateForDocumentDate = null;

    function isSmallScreen() {
      var w = angular.element($window);
      vm.width = w.width();
      $scope.$apply();
      // return document.body.clientWidth < 500;
    }

    $(window).resize(function () {
      isSmallScreen();
    });

    function isSearchActive() {
      return (
        vm.filters.filterBy.documentNumber.value ||
        vm.filters.filterBy.originName.value ||
        vm.filters.filterBy.createdDate.startDate ||
        vm.filters.filterBy.createdDate.endDate ||
        vm.filters.filterBy.documentDate.startDate ||
        vm.filters.filterBy.documentDate.endDate ||
        vm.filters.filterBy.documentValue.startValue ||
        vm.filters.filterBy.documentValue.startValue === 0 ||
        vm.filters.filterBy.documentValue.endValue ||
        vm.filters.filterBy.documentValue.endValue === 0 ||
        vm.filters.filterBy.documentType.value ||
        vm.filters.filterBy.hasViews.value ||
        vm.filters.filterBy.hasFiles.value
      );
    }

    function changeVisualization(value) {
      vm.filters.filterBy.hasViews.value = value;
      vm.page = 1;
      loadAll();
    }

    function changeFiles(value) {
      vm.filters.filterBy.hasFiles.value = value;
      vm.page = 1;
      loadAll();
    }

    function changeDocumentType(value) {
      vm.filters.filterBy.documentType.value = value;
      vm.page = 1;
      loadAll();
    }

    function reset() {
      vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
      loadAll();
    }

    function filterBy() {
      return {
        documentNumber: {
          name: 'documentNumber',
          value: undefined,
        },
        originName: {
          name: 'originName',
          value: undefined,
        },
        createdDate: {
          name: 'createdDate',
          startDate: undefined,
          endDate: undefined,
        },
        documentDate: {
          name: 'documentDate',
          startDate: undefined,
          endDate: undefined,
        },
        documentValue: {
          name: 'documentValue',
          startValue: undefined,
          endValue: undefined,
        },
        documentType: {
          name: 'documentType',
          value: undefined,
        },
        hasViews: {
          name: 'hasViews',
          value: undefined,
        },
        hasFiles: {
          name: 'hasFiles',
          value: undefined,
        },
      };
    }

    function initFilters() {
      vm.filters = PaginationUtil.getFilters($state.current.name, filterBy());

      vm.page = vm.filters.currentPage + 1;
      vm.predicate = vm.filters.orderBy;
      vm.reverse = vm.filters.orderReverse;
      loadAll();
    }

    vm.$onInit = function () {
      initFilters();
    };

    function loadAll() {
      vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);
      Document.queryForDigitalArchive(
        {
          page: vm.filters.currentPage,
          size: vm.filters.itemsPerPage,
          search: search(),
          sort: sort(),
        },
        onSuccess,
        onError
      );
    }

    function onSuccess(data, headers) {
      vm.links = ParseLinks.parse(headers('link'));
      vm.totalItems = headers('X-Total-Count');
      vm.queryCount = vm.totalItems;
      vm.documents = data;

      for (var i = 0; i < vm.documents.length; i++) {
        loadFilesIfNeeded(vm.documents[i]);
      }
      for (var i = 0; i < vm.documents.length; i++) {
        if (vm.documentsToExport[vm.documents[i].id] != null) {
          vm.documents[i].selected = true;
        }
      }
      checkSelectAll();
    }

    function onError(error) {
      AlertService.error(error.data.message);
    }

    function loadFilesIfNeeded(document) {
      if (document.hasFiles) {
        DocumentFile.queryByDocumentId({ documentId: document.id })
          .$promise.then(function (files) {
            document.files = files; // Attach the files to the document object
          })
          .catch(function (error) {
            console.error('Error retrieving document files:', error);
          });
      }
    }

    function sort() {
      if (!vm.predicate) {
        vm.predicate = 'createdDate';
      }
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      if (vm.predicate !== 'createdDate') {
        result.push('createdDate,desc');
      }
      return result;
    }

    function search() {
      var result = '';
      for (var x in vm.filters.filterBy) {
        if (x === 'hasViews') {
          if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
            if (vm.filters.filterBy[x].value === '1') {
              result += vm.filters.filterBy[x].name + ':true#AND,';
            } else if (vm.filters.filterBy[x].value === '0') {
              result += vm.filters.filterBy[x].name + ':false#AND,';
            }
          }
        } else {
          if (x === 'hasFiles') {
            if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
              if (vm.filters.filterBy[x].value === '1') {
                result += vm.filters.filterBy[x].name + ':true#AND,';
              } else if (vm.filters.filterBy[x].value === '0') {
                result += vm.filters.filterBy[x].name + ':false#AND,';
              }
            }
          } else {
            if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
              result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
            }
            if (vm.filters.filterBy[x] && vm.filters.filterBy[x].startDate) {
              if (x === 'documentDate') {
                result +=
                  vm.filters.filterBy[x].name +
                  '>=' +
                  moment(vm.filters.filterBy[x].startDate, 'DD/MM/YYYY')
                    .tz('Europe/Lisbon')
                    .set({
                      hours: 0,
                      minutes: 0,
                      seconds: 0,
                    })
                    .toISOString(true) +
                  '#AND,';
              } else {
                result +=
                  vm.filters.filterBy[x].name +
                  '>=' +
                  moment(vm.filters.filterBy[x].startDate, 'DD/MM/YYYY HH:mm')
                    .tz('Europe/Lisbon')
                    .set({
                      seconds: 0,
                    })
                    .toISOString(true) +
                  '#AND,';
              }
            }
            if (vm.filters.filterBy[x] && vm.filters.filterBy[x].endDate) {
              if (x === 'documentDate') {
                result +=
                  vm.filters.filterBy[x].name +
                  '<=' +
                  moment(vm.filters.filterBy[x].endDate, 'DD/MM/YYYY')
                    .tz('Europe/Lisbon')
                    .set({
                      hours: 23,
                      minutes: 59,
                      seconds: 59,
                    })
                    .toISOString(true) +
                  '#AND,';
              } else {
                result +=
                  vm.filters.filterBy[x].name +
                  '<=' +
                  moment(vm.filters.filterBy[x].endDate, 'DD/MM/YYYY HH:mm')
                    .tz('Europe/Lisbon')
                    .set({
                      seconds: 59,
                    })
                    .toISOString(true) +
                  '#AND,';
              }
            }
            if (vm.filters.filterBy[x] && (vm.filters.filterBy[x].startValue || vm.filters.filterBy[x].startValue === 0)) {
              result += vm.filters.filterBy[x].name + '>=' + vm.filters.filterBy[x].startValue + '#AND,';
            }
            if (vm.filters.filterBy[x] && (vm.filters.filterBy[x].endValue || vm.filters.filterBy[x].endValue === 0)) {
              result += vm.filters.filterBy[x].name + '<=' + vm.filters.filterBy[x].endValue + '#AND,';
            }
          }
        }
      }

      return result;
    }

    function download($event, document, fileId, fileType) {
      DocumentDownloadPdf.download($event, document, fileId, fileType);
    }

    function viewSelectedDocuments() {
      $uibModal.open({
        templateUrl: 'app/entities/digital-archive/document/selected-documents-dialog.html',
        controller: 'SelectedDocumentsController',
        controllerAs: 'vm',
        size: 'xl',
        resolve: {
          list: vm.documentsToExport,
          selectChange: function () {
            return vm.selectChange;
          },
          exportDocumentsFiles: function () {
            return vm.exportDocumentsFiles;
          },
          exportSelectedDocsToCSV: function () {
            return vm.exportSelectedDocsToCSV;
          },
          exportSelectedDocsToExcel: function () {
            return vm.exportSelectedDocsToExcel;
          },
        },
      });
    }

    function checkSelectAll() {
      var docsSelected = vm.documents.filter(function (doc) {
        return vm.documentsToExport[doc.id] != null;
      });
      vm.selectedAll = docsSelected.length === vm.documents.length;
    }

    function getTotalSelected() {
      var keys = Object.keys(vm.documentsToExport);
      return keys.length;
    }

    function selectChange(document) {
      if (document.selected) {
        vm.documentsToExport[document.id] = document;
        checkSelectAll();
      } else {
        delete vm.documentsToExport[document.id];
      }
    }

    function selectAll() {
      vm.documents.map(function (x) {
        x.selected = !vm.selectedAll;
        return x;
      });
      if (!vm.selectedAll) {
        for (var i = 0; i < vm.documents.length; i++) {
          vm.documentsToExport[vm.documents[i].id] = vm.documents[i];
        }
      } else {
        for (var j = 0; j < vm.documents.length; j++) {
          delete vm.documentsToExport[vm.documents[j].id];
        }
      }
    }

    function deselectOne() {
      vm.selectedAll = false;
    }

    function exportDocumentsFiles() {
      var docsSelected = [];
      Object.keys(vm.documentsToExport).forEach(function (key) {
        docsSelected.push(vm.documentsToExport[key]);
      });
      if (docsSelected.length === 0) {
        Notification.error({ message: $translate.instant('eDocumentsApp.document.message.noDocumentsSelected') });
        return;
      }
      var ids = [];
      for (var i = 0; i < docsSelected.length; i++) {
        if (docsSelected[i].files.length > 0) {
          ids.push(docsSelected[i].id);
        }
      }
      Document.downloadAsZip(ids).$promise.then(
        function (result) {
          Document.addDownloads(ids);
          DataUtils.viewServerFile(result);
          for (var i = 0; i < vm.documents.length; i++) {
            vm.documents[i].selected = false;
            selectChange(vm.documents[i]);
          }
        },
        function () {
          Notification.error({ message: $translate.instant('eDocumentsApp.document.message.fileRetrievalError') });
        }
      );
    }

    function clearInputDate(input) {
      if (input === 'startDateForCreatedDate') {
        vm.startDateForCreatedDate = null;
        vm.filters.filterBy.createdDate.startDate = null;
      } else if (input === 'endDateForCreatedDate') {
        vm.endDateForCreatedDate = null;
        vm.filters.filterBy.createdDate.endDate = null;
      } else if (input === 'startDateForDocumentDate') {
        vm.startDateForDocumentDate = null;
        vm.filters.filterBy.documentDate.startDate = null;
      } else if (input === 'endDateForDocumentDate') {
        vm.endDateForDocumentDate = null;
        vm.filters.filterBy.documentDate.endDate = null;
      }
    }
  }
})();
