(function () {
  'use strict';

  angular.module('eDocumentsApp').config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('communication-entity', {
        parent: 'entity',
        url: '/communication-entity?page&sort&search',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
          pageTitle: 'eDocumentsApp.communicationEntity.home.title',
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/communication-entity/communication-entities.html',
            controller: 'CommunicationEntityController',
            controllerAs: 'vm',
          },
        },
        params: {
          page: {
            value: '1',
            squash: true,
          },
          sort: {
            value: 'id,asc',
            squash: true,
          },
          search: null,
        },
        resolve: {
          pagingParams: [
            '$stateParams',
            'PaginationUtil',
            function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search,
              };
            },
          ],
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('communicationEntity');
              $translatePartialLoader.addPart('communicationType');
              $translatePartialLoader.addPart('communicationParameterType');
              $translatePartialLoader.addPart('communicationWorkflowStep');
              $translatePartialLoader.addPart('user-management');
              $translatePartialLoader.addPart('communicationDefinitionAuthority');
              $translatePartialLoader.addPart('amaSafeCredential');
              $translatePartialLoader.addPart('amaSafeCredentialStatus');
              $translatePartialLoader.addPart('amaSafeCredentialReason');
              $translatePartialLoader.addPart('externalAuthKey');
              $translatePartialLoader.addPart('externalAuthKeyStatus');
              $translatePartialLoader.addPart('communicationEntityConnectorParametersNames');
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            },
          ],
        },
      })
      .state('communication-entity-detail', {
        parent: 'communication-entity',
        url: '/communication-entity',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
          pageTitle: 'eDocumentsApp.communicationEntity.detail.title',
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/communication-entity/communication-entity-dialog.html',
            controller: 'CommunicationEntityDialogController',
            controllerAs: 'vm',
          },
        },
        params: {
          id: null,
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('communicationEntity');
              return $translate.refresh();
            },
          ],
          entity: [
            '$stateParams',
            'CommunicationEntity',
            function ($stateParams, CommunicationEntity) {
              if ($stateParams.id !== null) {
                return CommunicationEntity.get({ id: $stateParams.id }).$promise;
              }
              return null;
            },
          ],
          readOnly: function () {
            return true;
          },
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name || 'communication-entity',
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },
      })
      .state('communication-entity-detail.edit', {
        parent: 'communication-entity-detail',
        url: '/detail/edit',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/communication-entity/communication-entity-dialog.html',
            controller: 'CommunicationEntityDialogController',
            controllerAs: 'vm',
          },
        },
        resolve: {
          entity: [
            '$stateParams',
            'CommunicationEntity',
            function ($stateParams, CommunicationEntity) {
              return CommunicationEntity.get({ id: $stateParams.id }).$promise;
            },
          ],
          readOnly: function () {
            return false;
          },
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name || 'communication-entity',
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },
      })
      .state('communication-entity.new', {
        parent: 'communication-entity',
        url: '/new',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        },

        views: {
          'content@': {
            templateUrl: 'app/entities/communication-entity/communication-entity-dialog.html',
            controller: 'CommunicationEntityDialogController',
            controllerAs: 'vm',
          },
        },
        resolve: {
          entity: [
            'CommunicationEntity',
            function (CommunicationEntity) {
              return new CommunicationEntity();
            },
          ],
          readOnly: function () {
            return false;
          },
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name || 'communication-entity',
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },
      })
      .state('communication-entity.edit', {
        parent: 'communication-entity',
        url: '/edit',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/communication-entity/communication-entity-dialog.html',
            controller: 'CommunicationEntityDialogController',
            controllerAs: 'vm',
          },
        },
        params: {
          id: null,
        },
        resolve: {
          entity: [
            '$stateParams',
            'CommunicationEntity',
            function ($stateParams, CommunicationEntity) {
              if ($stateParams.id !== null) {
                return CommunicationEntity.get({ id: $stateParams.id }).$promise;
              }
              return null;
            },
          ],
          readOnly: function () {
            return false;
          },
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name || 'communication-entity',
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },
      })
      .state('communication-entity.delete', {
        parent: 'communication-entity',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/communication-entity/communication-entity-delete-dialog.html',
                controller: 'CommunicationEntityDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                  entity: [
                    'CommunicationEntity',
                    function (CommunicationEntity) {
                      return CommunicationEntity.get({ id: $stateParams.id }).$promise;
                    },
                  ],
                },
              })
              .result.then(
                function () {
                  $state.go('communication-entity', null, { reload: 'communication-entity' });
                },
                function () {
                  $state.go('^');
                }
              );
          },
        ],
      });
  }
})();
