(function () {
  'use strict';
  angular.module('eDocumentsApp').factory('Document', Document);

  Document.$inject = ['$resource', 'DateUtils', 'DataUtils'];

  function Document($resource, DateUtils, DataUtils) {
    var resourceUrl = 'api/documents/:id';

    return $resource(
      resourceUrl,
      {},
      {
        query: {
          method: 'GET',
          isArray: true,
        },
        queryForDigitalArchive: {
          method: 'GET',
          url: 'api/document-digital-archive',
          isArray: true,
        },
        queryForAdmin: {
          method: 'GET',
          url: 'api/document-for-admin',
          isArray: true,
        },
        findByDefinition: {
          method: 'GET',
          url: 'api/documents-by-definition/:id',
          isArray: true,
        },
        findByOrigin: {
          method: 'GET',
          url: 'api/documents-by-origin/:id',
          isArray: true,
        },
        findByDestination: {
          method: 'GET',
          url: 'api/documents-by-destination/:id',
          isArray: true,
        },
        redoFromStep: {
          method: 'GET',
          url: 'api/document-step-redo/:docId/:stepId',
        },
        doFromStep: {
          method: 'GET',
          url: 'api/document-step-do/:docId/:stepId',
        },
        addView: {
          method: 'POST',
          url: 'api/document-add-view',
        },
        addExternalView: {
          method: 'POST',
          url: 'api/external/document-add-view',
        },
        addDownload: {
          method: 'POST',
          url: 'api/document-add-download',
        },
        addDownloads: {
          method: 'POST',
          url: 'api/document-add-downloads',
        },
        addExternalDownloads: {
          method: 'POST',
          url: 'api/external/document-add-external-downloads',
        },
        addAllExternalDownloads: {
          method: 'POST',
          url: 'api/external/document-add-all-external-downloads',
        },
        downloadAsZip: {
          method: 'POST',
          url: 'api/download-as-zip',
          responseType: 'arraybuffer',
          transformResponse: function (data, headers) {
            return {
              data: data,
              filename: DataUtils.parseHeaderFilename(headers),
            };
          },
        },
        downloadExternalAsZip: {
          method: 'GET',
          url: 'api/external/download-external-as-zip/:hash',
          responseType: 'arraybuffer',
          transformResponse: function (data, headers) {
            return {
              data: data,
              filename: DataUtils.parseHeaderFilename(headers),
            };
          },
        },
        downloadAllExternalAsZip: {
          method: 'GET',
          url: 'api/external/download-all-external-as-zip',
          responseType: 'arraybuffer',
          transformResponse: function (data, headers) {
            return {
              data: data,
              filename: DataUtils.parseHeaderFilename(headers),
            };
          },
        },
        downloadAllDocsCSV: {
          method: 'GET',
          url: 'api/download-all-docs-as-csv',
          responseType: 'arraybuffer',
          transformResponse: function (data, headers) {
            return {
              data: data,
              filename: DataUtils.parseHeaderFilename(headers),
            };
          },
        },
        findByEntity: {
          method: 'GET',
          url: 'api/documents-by-entity/:id',
          isArray: true,
        },
        getInvoice: {
          method: 'GET',
          url: 'api/invoice-by-document/:id',
          isArray: false,
        },
        getOrder: {
          method: 'GET',
          url: 'api/order-by-document/:id',
          isArray: false,
        },
        get: {
          method: 'GET',
          transformResponse: function (data) {
            if (data) {
              data = angular.fromJson(data);
              data.documentDate = DateUtils.convertLocalDateFromServer(data.documentDate);
            }
            return data;
          },
        },
        getByExternalHash: {
          method: 'GET',
          url: 'api/external/document/:hash',
          transformResponse: function (data) {
            if (data) {
              data = angular.fromJson(data);
              data.documentDate = DateUtils.convertLocalDateFromServer(data.documentDate);
            }
            return data;
          },
        },
        getAllByExternalHash: {
          method: 'GET',
          url: 'api/external/documents',
          isArray: true,
        },
        update: {
          method: 'PUT',
          transformRequest: function (data) {
            var copy = angular.copy(data);
            copy.documentDate = DateUtils.convertLocalDateToServer(copy.documentDate);
            return angular.toJson(copy);
          },
        },
        save: {
          method: 'POST',
          transformRequest: function (data) {
            var copy = angular.copy(data);
            copy.documentDate = DateUtils.convertLocalDateToServer(copy.documentDate);
            return angular.toJson(copy);
          },
        },
        redoStepForDocumentsList: {
          method: 'GET',
          url: 'api/document-step-redo/from-list',
          isArray: true,
        },
        doStepForDocumentsList: {
          method: 'GET',
          url: 'api/document-step-do/from-list',
          isArray: true,
        },
        removeDocuments: {
          method: 'DELETE',
          url: 'api/documents/remove-documents',
        },
        approve: {
          method: 'GET',
          url: 'api/documents/approve/:id',
          transformResponse: function (data) {
            if (data) {
              data = angular.fromJson(data);
              data.documentDate = DateUtils.convertLocalDateFromServer(data.documentDate);
            }
            return data;
          },
        },
        approveByHashId: {
          method: 'GET',
          url: 'api/documents/external/approve/:hash',
          transformResponse: function (data) {
            if (data) {
              data = angular.fromJson(data);
              data.documentDate = DateUtils.convertLocalDateFromServer(data.documentDate);
            }
            return data;
          },
        },
        downloadNeedApproval: {
          method: 'GET',
          url: 'api/documents/download-need-approval/:id',
          transformResponse: function (data) {
            return { body: data };
          },
        },
        downloadNeedApprovalExternal: {
          method: 'GET',
          url: 'api/external/documents/download-need-approval/:hash',
          transformResponse: function (data) {
            return { body: data };
          },
        },
        downloadAllNeedApprovalExternal: {
          method: 'GET',
          url: 'api/external/documents/download-all-need-approval/:hash',
          transformResponse: function (data) {
            return { body: data };
          },
        },
      }
    );
  }
})();
