(function () {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('digital-archive-document', {
            parent: 'app',
            url: '/digital-archive/document?page&sort&search',
            data: {
                authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                pageTitle: 'eDocumentsApp.document.home.title',
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/digital-archive/document/documents.html',
                    controller: 'DigitalArchiveDocumentController',
                    controllerAs: 'vm',
                },
            },
            params: {
                page: {
                    value: '1',
                    squash: true,
                },
                sid: null,
                eid: null,
                did: null,
                oid: null,
                sort: {
                    value: 'createdDate,desc',
                    squash: true,
                },
                search: null,
            },
            resolve: {
                pagingParams: [
                    '$stateParams',
                    'PaginationUtil',
                    function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                        };
                    },
                ],
                loggeduser: [
                    'Principal',
                    function (Principal) {
                        return Principal.identity().$promise;
                    },
                ],
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('digitalArchive');
                        $translatePartialLoader.addPart('documentType');
                        $translatePartialLoader.addPart('documentStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    },
                ],
            },
        });
    }
})();
